import React from "react";
import { LanguageProvider } from "./src/context/LanguageContext";
import { navigate } from "gatsby";

export const onRouteUpdate = ({ location }) => {
  if (location.pathname === "/is/heim/") {
    navigate("/");
  }
};

export const wrapRootElement = ({ element }) => {
  return <LanguageProvider>{element}</LanguageProvider>;
};
