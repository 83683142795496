import React, { createContext, useState, useContext, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      allTranslation {
        nodes {
          language
          content
        }
      }
    }
  `);

  const translations = data.allTranslation.nodes.reduce((acc, node) => {
    acc[node.language] = JSON.parse(node.content); // Parse the content back to JSON
    return acc;
  }, {});

  const [language, setLanguage] = useState(() => {
    if (typeof window !== "undefined") {
      return localStorage.getItem("language") || "IS";
    }
    return "IS"; // Default language
  });

  const [isLoaded, setIsLoaded] = useState(false); // Track if translations are loaded

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedLanguage = localStorage.getItem("language");
      if (storedLanguage) {
        setLanguage(storedLanguage);
      }
      setIsLoaded(true); // Mark translations as loaded
    } else {
      setIsLoaded(true); // For SSR, mark it as loaded on the server-side
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      localStorage.setItem("language", language);
    }
  }, [language]);

  const t = (key) => translations[language][key] || key;

  const toggleLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  if (!isLoaded) {
    return null; // Render nothing while translations are loading
  }

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
